import config from '../config';

export enum ENV {
  PROD = 'prod',
  STAGING = 'staging',
  DEV = 'dev',
  ROB = 'rob',
  MARK = 'mark',
  MAX = 'max'
}

export const getApiUrl = (): string => {
  switch (process.env.REACT_APP_FANPOINT) {
    case ENV.PROD: {
      return config.apiGateway.URL_PROD;
    }
    case ENV.STAGING: {
      return config.apiGateway.URL_STAGING;
    }
    case ENV.DEV: {
      return config.apiGateway.DEV_URL;
    }
    case ENV.ROB: {
      return config.apiGateway.URL_ROB;
    }
    case ENV.MARK: {
      return config.apiGateway.URL_MARK;
    }
    case ENV.MAX: {
      return config.apiGateway.URL_MAX;
    }
    default:
      return config.apiGateway.DEV_URL;
  }
};

export const getUserPoolClientId = (): string => {
  switch (process.env.REACT_APP_FANPOINT) {
    case ENV.PROD: {
      return config.cognito.APP_CLIENT_ID_PROD;
    }
    case ENV.STAGING: {
      return config.cognito.APP_CLIENT_ID_STAGING;
    }
    case ENV.DEV: {
      return config.cognito.APP_CLIENT_ID_DEV;
    }
    case ENV.ROB: {
      return config.cognito.APP_CLIENT_ID_ROB;
    }
    case ENV.MARK: {
      return config.cognito.APP_CLIENT_ID_MARK;
    }
    case ENV.MAX: {
      return config.cognito.APP_CLIENT_ID_MAX;
    }
    default:
      return config.cognito.APP_CLIENT_ID_DEV;
  }
};

export const getUserPoolId = (): string => {
  switch (process.env.REACT_APP_FANPOINT) {
    case ENV.PROD: {
      return config.cognito.USER_POOL_ID_PROD;
    }
    case ENV.STAGING: {
      return config.cognito.USER_POOL_ID_STAGING;
    }
    case ENV.DEV: {
      return config.cognito.USER_POOL_ID_DEV;
    }
    case ENV.ROB: {
      return config.cognito.USER_POOL_ID_ROB;
    }
    case ENV.MARK: {
      return config.cognito.USER_POOL_ID_MARK;
    }
    case ENV.MAX: {
      return config.cognito.USER_POOL_ID_MAX;
    }
    default:
      return config.cognito.USER_POOL_ID_DEV;
  }
};
