const config = {
  apiGateway: {
    REGION: 'us-east-1',
    DEV_URL: 'https://y38k5fff65.execute-api.us-east-1.amazonaws.com/',
    URL_STAGING: 'https://y38k5fff65.execute-api.us-east-1.amazonaws.com/',
    URL_PROD: 'https://60wggfewe7.execute-api.us-east-1.amazonaws.com/',
    URL_ROB: 'https://1u48zl4y59.execute-api.us-east-1.amazonaws.com/',
    URL_MARK: 'https://kb9dyr4l5m.execute-api.us-east-1.amazonaws.com/',
    URL_MAX: 'https://0dh3srkmt9.execute-api.us-east-1.amazonaws.com/',
  },
  cognito: {
    REGION: 'us-east-1',
    //DEV
    USER_POOL_ID_DEV: 'us-east-1_2JoDFe4Zw',
    APP_CLIENT_ID_DEV: '462lghbv8tuigl7t7pjnuugeq9',
    IDENTITY_POOL_ID_DEV: 'us-east-1:9d5acead-349c-41bd-96af-e98e77653c5f',
    //STAGING
    USER_POOL_ID_STAGING: 'us-east-1_9uFo46Xkk',
    APP_CLIENT_ID_STAGING: '3ke3ag65hnkedmhmn6bc03lugb',
    IDENTITY_POOL_ID_STAGING: 'us-east-1:ca412791-659a-402e-a809-c9cc872897d2',
    //PROD
    USER_POOL_ID_PROD: 'us-east-1_iCWsbSeHV',
    APP_CLIENT_ID_PROD: '4pncikg7dn6fbjfru33nr2mhsu',
    IDENTITY_POOL_ID_PROD: 'us-east-1:5ae50782-141c-4e75-8e6c-2fbf8f072547',
    //ROB
    USER_POOL_ID_ROB: 'us-east-1_Q2ehSJvle',
    APP_CLIENT_ID_ROB: '69ttha8aa57lhd21u2eba29aj4',
    IDENTITY_POOL_ID_ROB: 'us-east-1:b7ff1ae8-5a07-4ff7-bb9c-1b126a231251',
    //MARK
    USER_POOL_ID_MARK: 'us-east-1_Ub17E7nEH',
    APP_CLIENT_ID_MARK: '50q5513k4v48ohp3gdt17mf4h1',
    IDENTITY_POOL_ID_MARK: 'us-east-1:39789a18-08bc-4209-a0a6-b7110e1fd2a8',
    //MAX
    USER_POOL_ID_MAX: 'us-east-1_zqbqK3X97',
    APP_CLIENT_ID_MAX: '40silnhjgqlcv39ba0aoldqho9',
    IDENTITY_POOL_ID_MAX: 'us-east-1:447269f4-225c-4710-9fa7-3a516370192c',
  }

  // google: {
  //   GTM: ''
  // }
};

export default config;
